/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_5cb4d6';src: local("Arial");ascent-override: 94.72%;descent-override: 29.60%;line-gap-override: 0.00%;size-adjust: 104.73%
}.__className_5cb4d6 {font-family: '__DM_Sans_5cb4d6', '__DM_Sans_Fallback_5cb4d6';font-style: normal
}

.CustomText_text__ZvFKy{color:#292929}.CustomText_bold__iIFqC{font-weight:700}
.CustomButton_button__IsApx{margin:0;padding:0;width:auto;overflow:visible;white-space:nowrap;background:rgba(0,0,0,0);cursor:pointer;width:100%;padding:12px 16px;display:flex;justify-content:center;align-items:center;border-radius:8px;border:1px solid rgba(0,0,0,0);text-align:center;font-size:16px}.CustomButton_primary__iMmaC{background-color:#ffd60a}.CustomButton_primary__iMmaC:hover{background-color:#e6c109}.CustomButton_secondary__ZzuXQ{background-color:#fff;border:1px solid #c9c9c9}.CustomButton_secondary__ZzuXQ:hover{background-color:#e6e6e6}.CustomButton_disabled__NHInH{background-color:#c9c9c9}.CustomButton_disabled__NHInH>p{color:#969696}.CustomButton_disabled__NHInH div{opacity:.3}.CustomButton_disabled__NHInH:hover{background-color:#c9c9c9}.CustomButton_transparent__ZV2AH{background-color:rgba(0,0,0,0)}.CustomButton_transparent__ZV2AH>p{color:#ff5b01}.CustomButton_transparent__ZV2AH:hover{background-color:rgba(0,0,0,0)}.CustomButton_disabledTransparent__sN3Og{background-color:rgba(0,0,0,0)}.CustomButton_disabledTransparent__sN3Og>p{color:#969696}.CustomButton_disabledTransparent__sN3Og:hover{background-color:rgba(0,0,0,0)}.CustomButton_icon__Y5SV5{margin-right:12px;display:flex}.CustomButton_smallIcon__afDzi{margin-right:8px}.CustomButton_smallButton___7jR5{padding:8px 16px}.CustomButton_smallText__zJExN{font-size:12px}
.GetStartedButton_btnContainer__fvD8a{display:flex;justify-content:flex-start;align-items:center}.GetStartedButton_btnContainer__fvD8a .GetStartedButton_btn__AZx4t{max-width:95px;padding:0;text-align:left;justify-content:flex-start}.GetStartedButton_btnContainer__fvD8a .GetStartedButton_btn__AZx4t>p{color:#ff5b01}
.AppShowcase_appShowcase__AyrMZ{height:100%;width:100%;display:flex;flex-direction:column;gap:64px;padding-top:32px;padding-left:32px;padding-right:32px;overflow:hidden;background-color:#fff;border-radius:16px}@media only screen and (min-width: 768px){.AppShowcase_appShowcase__AyrMZ{flex-direction:row;gap:30px}}.AppShowcase_appShowcase__AyrMZ .AppShowcase_content__BKsX6{display:flex;flex-direction:column;max-width:300px}@media only screen and (min-width: 768px){.AppShowcase_appShowcase__AyrMZ .AppShowcase_content__BKsX6{max-width:100%;flex:1 1}}.AppShowcase_appShowcase__AyrMZ .AppShowcase_content__BKsX6 .AppShowcase_mainText__aHjXC{color:#ff5b01;font-size:20px;margin-bottom:18px;line-height:24px}.AppShowcase_appShowcase__AyrMZ .AppShowcase_content__BKsX6 .AppShowcase_mainText__aHjXC .AppShowcase_underline__v71sV{color:#ff5b01;text-decoration:underline}.AppShowcase_appShowcase__AyrMZ .AppShowcase_content__BKsX6 .AppShowcase_secondaryText__6JwhS{font-size:14px;margin-bottom:40px}@media only screen and (min-width: 768px){.AppShowcase_appShowcase__AyrMZ .AppShowcase_content__BKsX6{margin-bottom:29px}}.AppShowcase_appShowcase__AyrMZ .AppShowcase_imageContainer__2FmKX{min-height:257px;position:relative}@media only screen and (min-width: 768px){.AppShowcase_appShowcase__AyrMZ .AppShowcase_imageContainer__2FmKX{flex:1 1;min-height:255px}}.AppShowcase_appShowcase__AyrMZ .AppShowcase_imageContainer__2FmKX .AppShowcase_image___Siwv{width:600px;height:400px;position:absolute;left:56px}@media only screen and (min-width: 768px){.AppShowcase_appShowcase__AyrMZ .AppShowcase_imageContainer__2FmKX .AppShowcase_image___Siwv{width:434px;height:248px;position:absolute;bottom:-23% !important;right:-135% !important;left:31px}}.AppShowcase_appShowcase__AyrMZ .AppShowcase_mbSmall__uIFzz{margin-bottom:18px}.AppShowcase_appShowcase__AyrMZ .AppShowcase_mgLarge__RLEgE{margin-bottom:40px}
.FormNotFound_container__5xBip{display:flex;justify-content:center;flex-direction:column;gap:24px;height:100%;max-width:375px;margin:24px 16px 24px}@media only screen and (min-width: 768px){.FormNotFound_container__5xBip{max-width:490px;margin:158px auto 80px}}.FormNotFound_container__5xBip .FormNotFound_wrapper__byAnv{display:flex;justify-content:center;align-items:center;flex-direction:column;padding:32px 24px;background-color:#fff;border-radius:16px}.FormNotFound_container__5xBip .FormNotFound_wrapper__byAnv .FormNotFound_errorBox___tZn3{background-color:#fbe9e9;padding:23px;border-radius:100px;display:flex;justify-content:center;align-items:center;margin-bottom:40px}.FormNotFound_container__5xBip .FormNotFound_wrapper__byAnv .FormNotFound_title__cBKOM{width:100%;text-align:center;font-size:28px;line-height:28px;margin-bottom:16px;text-align:center;font-size:24px}.FormNotFound_container__5xBip .FormNotFound_wrapper__byAnv .FormNotFound_info__N_ZKA{color:#636363;font-size:16px;text-align:center;line-height:20px;padding:0 30px}
.Navbar_navbar__3X_5w{height:64px;width:100%;display:flex;justify-content:space-between;align-items:center;background-color:#fff;padding:0 16px}@media only screen and (min-width: 768px){.Navbar_navbar__3X_5w{padding:0 120px}}.Navbar_navbar__3X_5w a{display:flex}.Navbar_navbar__3X_5w .Navbar_btn__PmfCj{width:150px;height:30px;font-size:12px}
.Layout_layout__vMu_3{width:100%;display:flex;flex-direction:column;align-items:center;background:url(/_next/static/media/mobile-short.7b87955b.png);background-repeat:no-repeat;background-position:center;background-size:cover;-webkit-background-size:cover;-moz-background-size:cover;-o-background-size:cover}@media only screen and (min-width: 375px){.Layout_layout__vMu_3{background:url(/_next/static/media/mobile.f11e2e8e.png);background-repeat:no-repeat;background-position:center;background-size:cover;-webkit-background-size:cover;-moz-background-size:cover;-o-background-size:cover}}@media only screen and (min-width: 768px){.Layout_layout__vMu_3{background:url(/_next/static/media/desktop-vertical.de649e83.png);background-repeat:no-repeat;background-position:center;background-size:cover;-webkit-background-size:cover;-moz-background-size:cover;-o-background-size:cover}}@media only screen and (min-width: 1028px){.Layout_layout__vMu_3{background:url(/_next/static/media/desktop-horizontal.a5b9ce5e.png);background-repeat:no-repeat;background-position:center;background-size:cover;-webkit-background-size:cover;-moz-background-size:cover;-o-background-size:cover}}.Layout_layout__vMu_3 .Layout_content__JllAo{width:100%;display:flex;justify-content:center}@media only screen and (min-width: 768px){.Layout_center__D63nn{margin:auto}}.Layout_flex__vHyGu{flex:1 1}
.RenderForm_container__3RC5s{display:flex;justify-content:center;flex-direction:column;gap:24px;height:calc(100vh - 115px);max-width:375px;width:100%;margin:24px 16px 24px;overflow:hidden}@media only screen and (min-width: 768px){.RenderForm_container__3RC5s{max-width:490px;margin:0 auto 80px}}.RenderForm_container__3RC5s .RenderForm_title__Of_YO{width:100%;text-align:center;font-size:28px;line-height:32px;margin-bottom:16px;overflow:hidden;text-overflow:ellipsis}.RenderForm_container__3RC5s .RenderForm_wrapper__gfkPd{display:flex;justify-content:center;align-items:center;flex-direction:column;gap:8px;padding:32px 24px;background-color:#fff;border-radius:16px}.RenderForm_container__3RC5s .RenderForm_wrapper__gfkPd .RenderForm_box__ksHw7{display:flex;flex-direction:column;gap:4px;width:100%;min-height:60px;padding:12px;background-color:#fafafa;border-radius:12px}.RenderForm_container__3RC5s .RenderForm_wrapper__gfkPd .RenderForm_box__ksHw7 .RenderForm_label__SEmYC{font-size:14px;color:#636363}.RenderForm_container__3RC5s .RenderForm_wrapper__gfkPd .RenderForm_box__ksHw7 .RenderForm_info__8BX_w{font-size:14px}.RenderForm_container__3RC5s .RenderForm_signaturesContainer__vr5nI{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:24px}.RenderForm_container__3RC5s .RenderForm_flex__5K8RK{display:flex;flex-direction:row;justify-content:space-between;width:100%}.RenderForm_container__3RC5s .RenderForm_flex__5K8RK .RenderForm_icon__lxpLt{cursor:pointer}.RenderForm_container__3RC5s .RenderForm_buttonContainer__q7xna{display:flex;justify-content:space-between;width:100%}.RenderForm_container__3RC5s .RenderForm_buttonContainer__q7xna .RenderForm_button__lCoX6{width:48%}
.FormDetails_spinner__nFtks{height:100vh;width:100vw;display:flex;justify-content:center;align-items:center}
