@import '@styles/colors.module.scss';
@import '@styles/mixins/media';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  height: calc(100vh - 115px);
  max-width: 375px;
  width: 100%;
  margin: 24px 16px 24px;
  overflow: hidden;

  @include media('tablet') {
    max-width: 490px;
    margin: 0 auto 80px;
  }

  .title {
    width: 100%;

    text-align: center;
    font-size: 28px;
    line-height: 32px;

    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    padding: 32px 24px;

    background-color: $white;
    border-radius: 16px;

    .box {
      display: flex;
      flex-direction: column;
      gap: 4px;

      width: 100%;
      min-height: 60px;
      padding: 12px;

      background-color: $accentGray;
      border-radius: 12px;

      .label {
        font-size: 14px;
        color: $label;
      }

      .info {
        font-size: 14px;
      }
    }
  }

  .signaturesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;

    .icon {
      cursor: pointer;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .button {
      width: 48%;
    }
  }
}
